<template>
    <div class="root">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{$route.name}}</span>
            </div>

            <!-- 搜索表单 -->
            <el-form :inline="true" :model="searchData">
                <!-- <el-form-item label="标题">
                    <el-input v-model="searchData.title" size="small"></el-input>
                </el-form-item> -->
                <el-form-item label="状态">
                    <el-select v-model="searchData.status" placeholder="请选择" size="small" @change="search">
                        <el-option label="" value=""></el-option>
                        <el-option label="未审核" :value="0"></el-option>
                        <el-option label="已通过" :value="1"></el-option>
                        <el-option label="未通过" :value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <!-- 表格 -->
            <el-table :data="tableData" stripe style="width: 100%" v-loading="tableloading">
                <!-- 表格数据列 -->
                <el-table-column label="标题">
                    <template slot-scope="item">《{{ item.row.title }}》</template>
                </el-table-column>
                <el-table-column prop="content" label="留言内容">
                    <template slot-scope="item">
                        <p class="text-wrap">{{ item.row.content }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="头像">
                    <template slot-scope="item">
                        <img class="avatar" :src="`https://peal.cc/avatar/${item.row.avatar}.jpg`" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="email" label="回复邮箱">
                </el-table-column>
                <el-table-column prop="create_time" label="留言时间"></el-table-column>
                <el-table-column label="状态" width="90">
                    <template slot-scope="item">
                        <el-tag v-if="item.row.status === 0">未审核</el-tag>
                        <el-tag v-if="item.row.status === 1" type="success">已通过</el-tag>
                        <el-tag v-if="item.row.status === 2" type="danger">已拒绝</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="210">
                    <template slot-scope="item">
                        <el-button v-if="item.row.status !== 2" size="mini" @click="reply(item.row, item.$index)">回复</el-button>
                        <el-button v-if="item.row.status === 0" type="success" size="mini" @click="changeStatus(item.$index, 1)">通过</el-button>
                        <el-button v-if="item.row.status !== 2" type="danger" size="mini" @click="changeStatus(item.$index, 2)">拒绝</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 表格分页 -->
            <div class="pager">
                <el-pagination background layout="prev, pager, next" :total="pageTotal" :current-page="searchData.page" @current-change="changePage"></el-pagination>
            </div>

        </el-card>
    </div>
</template>

<script>
    // const statusArr = ['未审核', '已通过', '未通过']

    export default {
        data() {
            return {
                // 搜索数据
                searchData: {
                    status: 0,
                    page: 1
                },
                // 表格数据
                pageTotal: 0,
                tableloading: true,
                tableData: [],
            }
        },
        created() {
            this.loadTable()
        },
        methods: {
            // 表格请求
            loadTable() {
                let that = this
                this.ajax({
                    url: '/admin/comment',
                    data: that.searchData,
                    beforeSend() {
                        that.tableloading = true
                    },
                    complete() {
                        that.tableloading = false
                    },
                    success(res) {
                        that.pageTotal = res.data.count
                        that.tableData = res.data.list
                    },
                    error(res) {
                        that.$message.error(res.message);
                    },
                })
            },
            // 搜索
            search() {
                this.searchData.page = 1
                this.loadTable()
            },
            // 翻页
            changePage(page) {
                this.searchData.page = page
                this.loadTable()
            },
            reply(data, index) {
                let that = this
                this.$prompt('请输入回复内容', '回复', {
                    confirmButtonText: '提交',
                    showCancelButton: false,
                    inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
                    inputErrorMessage: '请输入正确的内容'
                }).then(value => {
                    value = value.value
                    that.ajax({
                        url: '/admin/comment',
                        type: 'post',
                        data: {
                            blog_id: data.blog_id,
                            content: value,
                            comment: data.id
                        },
                        beforeSend() {
                            that.tableloading = true
                        },
                        success() {
                            that.ajax({
                                url: '/admin/comment',
                                type: 'put',
                                data: {
                                    id: data.id,
                                    status: 1
                                },
                                complete() {
                                    that.tableloading = false
                                },
                                success(res) {
                                    if(that.searchData.status === 0) that.tableData.splice(index, 1)
                                    that.$message({
                                        message: res.message || '已回复并通过该留言',
                                        type: 'success'
                                    })
                                },
                                error(res) {
                                    that.$message.error(res.message);
                                },
                            })
                        },
                        error(res) {
                            that.$message.error(res.message);
                        },
                    })
                })
            },
            // 改变状态
            changeStatus(index, status) {
                let that = this
                let data = that.tableData[index]
                // that.$confirm('确认审核为【' + statusArr[status] + '】？')
                // .then(() => {
                that.ajax({
                    url: '/admin/comment',
                    type: 'put',
                    data: {
                        id: data.id,
                        status
                    },
                    beforeSend() {
                        that.tableloading = true
                    },
                    complete() {
                        that.tableloading = false
                    },
                    success() {
                        // that.tableData[index].status = status
                        that.tableData.splice(index, 1)
                        that.$message({
                            message: '操作成功',
                            type: 'success'
                        })
                    },
                    error(res) {
                        that.$message.error(res.message);
                    },
                })
                // })
                // .catch(() => {})
            },
        }
    }
</script>

<style lang="less" scoped>
    .pager {
        padding-top: 20px;
        text-align: right;
    }

    .text-wrap {
        white-space: pre-wrap;
    }

    .avatar {
        width: 50px;
        border-radius: 50%;
    }
</style>